@charset "UTF-8";
.transition, .menuSlideLink, .menuSlideLink:focus, .menuSlideLink:before, .menuSlideLink:focus:before, .menuSlideLink__text, .breadcrumbs a, .content, #add-manager .selectric-items li {
  transition: .0s ease-in;
}

.statistic {
  margin-bottom: 60px;
}

.item-statistic {
  padding: 0 20px 60px 20px;
  background-color: #f1f3f8;
  height: 164px;
  margin-bottom: 30px;
}

.item-statistic h4 {
  color: #9eb670;
  font-size: 26px;
  font-weight: 700;
  padding-top: 20px;
  height: 100px;
}

.item-statistic-value {
  color: #686868;
  font-size: 23px;
}

.item-statistic-block {
  padding: 0 30px;
}

.slidebarMenu .menuBtn {
  display: inline-block;
  width: 31px;
  height: 23px;
  margin-left: 20px;
  margin-bottom: 70px;
}

.slidebarMenu .menuBtn span {
  background-color: #fff;
}

.slidebarMenu .menuBtn span:after, .slidebarMenu .menuBtn span:before {
  background-color: #fff;
}

@media (min-width: 1200px) {
  .slidebarMenu.active .menuSlideLink__text {
    padding-left: 20px;
    padding-right: 0;
    width: auto;
  }
  .slidebarMenu .menuSlideLink__text {
    padding-left: 0;
    padding-right: 0;
    width: 0;
  }
}

@media (max-width: 1199px) {
  .slidebarMenu.active .menuSlideLink__text {
    padding-left: 40px;
    padding-right: 30px;
    width: auto;
  }
}

@media (max-width: 991px) {
  .slidebarMenu .menuBtn {
    margin-bottom: 30px;
  }
}

.menuSlideLink, .menuSlideLink:focus {
  position: relative;
  display: flex;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: center;
  height: 54px;
  padding-left: 20px;
  padding-right: 20px;
  color: #fff;
  font-size: 22px;
  width: 100%;
}

@media (max-width: 1449px) {
  .menuSlideLink, .menuSlideLink:focus {
    font-size: 20px;
  }
}

.menuSlideLink:before, .menuSlideLink:focus:before {
  content: '';
  position: absolute;
  display: block;
  width: 6px;
  height: 100%;
  top: 0;
  left: -6px;
  background-color: #1d433d;
}

.menuSlideLink__icon {
  width: 30px;
  height: 30px;
}

.menuSlideLink__text {
  padding-left: 40px;
  padding-right: 30px;
  width: auto;
  line-height: initial;
  overflow: hidden;
}

@media (max-width: 1449px) {
  .menuSlideLink__text {
    padding-left: 20px;
    padding-right: 0;
  }
}

.menuSlideLink:hover, .menuSlideLink:active, .menuSlideLink.active {
  color: #fff;
  background-color: #9eb670;
  text-decoration: none;
}

.menuSlideLink:hover:before, .menuSlideLink:active:before, .menuSlideLink.active:before {
  left: 0;
}

.menuSlideLink:active:before {
  left: -6px;
}

.menuSlideLink:focus {
  color: #fff;
}

@media (max-width: 1449px) {
  .menuSlideLink__text {
    padding-left: 20px;
    padding-right: 0;
  }
}

@media (max-width: 1319px) {
  .menuSlideLink__text {
    padding-left: 0;
    padding-right: 0;
    width: 0;
  }
}

@media (max-width: 1099px) {
  .menuSlideLink__text {
    padding-left: 20px;
    width: auto;
  }
}

@media (max-width: 767px) {
  .menuSlideLink, .menuSlideLink:focus {
    height: 37px;
  }
  .menuSlideLink__icon {
    width: 24px;
    height: 24px;
  }
  .menuSlideLink__text {
    font-size: 18px;
  }
}

.breadcrumbs {
  padding: 20px 0;
  background-color: #f9f9f9;
  margin-bottom: 30px;
}

.breadcrumbs li {
  display: inline-block;
}

.breadcrumbs li:last-child a {
  cursor: default;
  color: #64855b;
  margin-right: 0;
}

.breadcrumbs li:last-child a:after {
  display: none;
}

.breadcrumbs a {
  color: #9eb670;
  margin-right: 10px;
}

.breadcrumbs a:hover {
  color: #64855b;
}

.breadcrumbs a:active {
  color: #9eb670;
}

.breadcrumbs a:after {
  content: '/';
  margin-left: 10px;
  color: #64855b;
}

@media (max-width: 767px) {
  .breadcrumbs {
    padding: 10px 0;
    margin-bottom: 15px;
  }
  .breadcrumbs a {
    font-size: 14px;
    margin-right: 5px;
  }
  .breadcrumbs a:after {
    margin-left: 5px;
  }
}

.content.fixed {
  padding-top: 55px;
}

.account-title {
  font-size: 35px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.account-title_noMargin {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .account-title {
    font-size: 25px;
    margin-bottom: 10px;
  }
}

.section-title {
  margin-top: 5px;
}

.button-block {
  margin-top: 50px;
}

.touch-content {
  display: none;
}

.touch_add {
  margin-bottom: 20px;
}

.touch + .button-center {
  margin-top: 40px;
}

.touch.has-error {
  border-color: #a31616;
}

.touch.has-error .touch-content {
  display: block;
}

.touch-title, .touch-title-wrapper .btn-group {
  cursor: pointer;
  transition: .2s ease;
}

.touch-title:hover, .touch-title-wrapper .btn-group:hover {
  color: #9eb670;
}

.margin_top {
  margin-top: 20px;
}

@media (max-width: 767px) {
  .section-title {
    margin-top: 20px;
    margin-bottom: 5px;
  }
  .button-block {
    text-align: center;
    margin-top: 30px;
    padding-left: 15px;
  }
  .button-block .button {
    height: 50px;
    margin-bottom: 20px;
  }
  .button-block .button_link {
    line-height: 46px;
  }
  .button-block .button_link:last-child {
    margin-bottom: 0;
  }
}

.touch-title-wrapper {
  margin-bottom: 10px;
  border-bottom: 1px solid #9eb670;
}

.logo-block {
  width: 120px;
  margin-bottom: 30px;
  box-sizing: content-box;
  padding: 20px;
}

.logo-block img {
  width: 100%;
}

.is-verify {
  position: relative;
}

.is-verify .itemDesc__val:after {
  content: 'Верифицированно';
  color: #9eb670;
  margin-left: 5px;
  font-size: 14px;
}

.is-verify.error {
  margin-bottom: 40px;
}

.is-verify.error .verify {
  display: flex;
}

.is-verify.error .itemDesc__val:after {
  display: none;
}

.verify {
  display: block;
  position: relative;
  width: 100%;
  top: calc(100% + 5px);
  left: 0;
  margin-bottom: 15px;
  justify-content: space-between;
  align-items: center;
}

.verify__btn {
  width: auto;
  max-width: inherit;
  display: inline-block;
  height: 30px;
  font-size: 14px;
  line-height: 27px;
}

.verify__input {
  display: inline-block;
  flex-grow: 1;
  margin: 0 5px;
}

.verify__input .default-input {
  height: 30px;
  padding: 0 10px;
  font-size: 12px;
  border-radius: 20px;
}

.inform-item {
  margin: 30px 0 20px 0;
}

.inform-item_border {
  padding: 20px 10px;
  border: 1px solid #9eb670;
}

.inform-item li {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 15px;
}

.inform-item li:last-child {
  margin-bottom: 0;
}

.inform-item .itemDesc__title {
  min-width: 240px;
}

.changeRate, .editData {
  text-transform: none;
  margin-bottom: 15px;
}

.editData:focus {
  transform: none;
}

@media (max-width: 767px) {
  .logo-block {
    width: 80px;
    padding: 10px;
    margin-bottom: 5px;
  }
  .inform-item li {
    display: block;
  }
  .inform-item .itemDesc {
    display: block;
  }
  .changeRate, .editData {
    line-height: 42px;
    font-size: 18px;
  }
  .verify {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .verify__input {
    flex-grow: 0;
    max-width: 130px;
  }
}

@media (max-width: 399px) {
  .is-verify.error {
    margin-bottom: 80px;
  }
  .verify__btn_send {
    margin-top: 10px;
  }
}

.itemDesc {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
}

.itemDesc__title {
  font-weight: 700;
  margin-right: 10px;
  margin-top: -2px;
}

.itemDesc__val {
  font-weight: 400;
  word-break: break-all;
}

@media (max-width: 767px) {
  .itemDesc {
    font-size: 15px;
  }
  .itemDesc__title {
    margin-top: 0;
    font-size: 18px;
    margin-right: 0;
  }
}

.button_add {
  line-height: 42px;
}

.button_add svg {
  fill: #fff;
  width: 20px;
  height: 20px;
  margin-left: 0;
  margin-right: 10px;
}

.table-block {
  margin-top: 30px;
}

.table-block__title {
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .table-block__title {
    font-size: 24px;
  }
  .table-block__table {
    margin: 0 -15px;
  }
}

.table {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}

.table__head {
  font-family: "Neuron";
  font-weight: 700;
}

.table__head-min .table__col {
  font-size: 15px;
}

.sort-btn {
  margin-left: 10px;
  height: 12px;
  background-color: transparent;
}

.sort-btn.active {
  transform: rotate(180deg);
}

.table__row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.table__col {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 13px 10px;
  font-family: "OpenSans";
  font-size: 16px;
  text-align: left;
  background-color: #f1f3f8;
}

.table__col:nth-child(odd) {
  background-color: #e6e9f2;
}

.table__col_numb {
  width: 52px;
  min-width: 52px;
  justify-content: center;
  font-weight: bold;
}

.table__col_phone, .table__col_email {
  justify-content: space-between;
}

.table__col_phone div, .table__col_email div {
  width: 100%;
  text-align: left;
}

.table__col_text {
  display: block;
}

.table__col_name a {
  color: #373d41;
}

.table__col_name a:hover {
  text-decoration: underline;
}

.table__col_icon {
  width: 165px;
  min-width: 165px;
  justify-content: center;
}

.table__col_icon svg {
  margin: 0 3px;
  width: 20px;
  height: 22px;
  fill: #9eb670;
}

.table__col_icon svg:hover {
  fill: #64855b;
}

.table__col_icon-one {
  width: 60px;
  min-width: 60px;
}

.table__content .table__row:nth-child(odd) .table__col {
  background-color: #fff;
}

@media (max-width: 991px) {
  .table__col {
    font-size: 14px;
    line-height: 1.1;
    height: auto;
    padding: 10px 10px;
  }
}

@media (max-width: 767px) {
  .table__head {
    display: none;
  }
  .table__row {
    flex-direction: column;
    margin-bottom: 10px;
  }
  .table__col {
    position: relative;
    width: 100%;
    justify-content: flex-start;
    padding: 18px 15px 10px 15px;
    line-height: 1.2;
    background-color: transparent;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(100, 133, 91, 0.4);
  }
  .table__col:nth-child(odd) {
    background-color: transparent;
  }
  .table__col:before {
    content: attr(data-name);
    position: absolute;
    top: 0;
    left: 15px;
    font-family: "Neuron";
    font-weight: 700;
    font-size: 14px;
  }
  .table__col_numb:before {
    position: static;
    margin-right: 3px;
  }
  .table__col_name a {
    text-decoration: underline;
  }
  .table__col_phone, .table__col_email {
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .table__col_phone div, .table__col_email div {
    width: auto;
    display: inline-block;
    margin-right: 10px;
  }
  .table__col_icon {
    padding-top: 0;
    justify-content: center;
    margin-bottom: 0;
    border: 0;
  }
  .table__col_icon svg {
    width: 25px;
    height: 25px;
    margin: 0 10px;
  }
  .table__content .table__row {
    background-color: #e6e9f2;
  }
  .table__content .table__row:nth-child(odd) {
    background-color: #f1f3f8;
  }
  .table__content .table__row:nth-child(odd) .table__col {
    background-color: transparent;
  }
}

.tooltip {
  background-color: #000;
  color: #fff;
}

.table__col {
  background-color: transparent;
  border-right: 1px solid #f1f3f8;
  border-bottom: 1px solid #f1f3f8;
}

.table__col:first-child {
  border-left: 1px solid #f1f3f8;
}

.table__head .table__row .table__col:nth-child(odd) {
  background-color: #e6e9f2;
  border: none;
}

.table__head .table__col {
  background-color: #f1f3f8;
}

.table__head .table__row .table__col {
  background-color: #f1f3f8;
}

.table__row .table__col {
  background-color: transparent;
}

.confirm-modal-touches {
  color: #9eb670;
}

.confirm-modal-touches:hover, .confirm-modal-touches:focus {
  color: #64855b;
}

.table__col_icon-debt {
  width: 210px;
  min-width: 210px;
}

@media (max-width: 767px) {
  .modal .table__col {
    background-color: transparent;
    border: 1px solid #f1f3f8;
    margin-bottom: -1px;
  }
  .table-block__table {
    margin: 0;
  }
  .modal .table__row {
    margin-bottom: 0;
  }
}

.table__col.table__col_content i {
  display: none;
  transition: .2s ease;
}

.table__col.table__col_content i:hover {
  cursor: pointer;
  color: #9eb670;
}

.centered {
  text-align: center;
  margin-top: 30px;
}

@media (min-width: 1101px) {
  .container {
    width: 100%;
    padding-left: 220px;
    transition: .2s ease-out;
  }
  .content-wrap.active-wrap .container {
    padding-left: 100px;
  }
}

.button-block-save,
.button-block-delete {
  margin-top: 17px;
}

.btn-input-file {
  margin-top: 20px;
  background-color: #fff;
  color: #343b3f;
}

.button_add-big {
  line-height: 55px;
  padding: 0 15px;
}

@media (min-width: 992px) {
  .line-fields {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .line-fields > div:last-child {
    margin-right: 0;
  }
  .line-fields_three > div {
    width: 33.3%;
    margin-right: 20px;
  }
  .line-fields_two > div {
    width: 50%;
    margin-right: 20px;
  }
}

.footer__bottom {
  margin-top: 15px;
  border-top: 1px solid #9eb670;
}

.comment-count-block {
  display: block;
  position: absolute;
  top: -13px;
  left: 15px;
  width: 20px;
  height: 20px;
  background-color: #ff0000;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
}

.confirm-modal-comment, .confirm-modal-touches {
  position: relative;
  display: block;
}

.modal_content_comment {
  padding: 0 15px 15px;
}

#comment-table {
  margin-bottom: 15px;
}

#current-text-comment, #current-text-comment-status, #current-text-admin-comment {
  width: 100%;
  border: 1px solid #ccc;
  margin-bottom: 5px;
}

.comment-row {
  background-color: #eaeaea;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 5px;
  word-wrap: break-word;
}

.user-info {
  font-size: 12px;
}

.btn-comment-add {
  margin-left: calc(100% - 200px);
  margin-bottom: 15px;
}

.text-right {
  text-align: right;
  font-size: 18px;
}

#modal-comment h3, #admin-comment h3 {
  font-size: 21px;
}

#cropper-modal-signature_file .modal-footer .row {
  display: none;
}

#cropper-modal-_stamp_file .modal-footer .row {
  display: none;
}

#cropper-modal-_logo_file .modal-footer .row {
  display: none;
}

#cropper-modal-signature_file .modal-content {
  width: 700px;
}

#cropper-modal-_stamp_file .modal-content {
  width: 700px;
}

#cropper-modal-signature_file .modal-dialog.modal-lg {
  width: 700px;
}

#cropper-modal-_stamp_file .modal-dialog.modal-lg {
  width: 700px;
}

#cropper-modal-signature_file .modal-dialog {
  margin-top: 50px;
}

#cropper-modal-_stamp_file .modal-dialog {
  margin-top: 50px;
}

.addDoc.listPay .input-wrap_select .selectric .label {
  padding-left: 5px;
}

#form_step_8 .error-block {
  position: relative;
  top: 3px;
}

#form_step_7 .test_mail_settings_and_send {
  max-width: 250px;
}

.quick_start_btn {
  max-width: 329px;
}

#add-manager .selectric {
  background-color: transparent;
  border: none;
  padding-bottom: 3px;
  margin-top: 3px;
}

#add-manager .label {
  font-size: 22px;
  margin-right: 0;
  margin-left: 5px;
  color: #373d41;
  padding: 0;
  text-align: left;
}

#add-manager .selectric-wrapper .button {
  width: inherit;
  height: inherit;
  display: inline-block;
  border: 0;
  right: -8px;
  top: 20px;
  font-size: 22px;
  color: #9eb670;
}

#add-manager .selectric-wrapper .button:after {
  display: none;
}

#add-manager .selectric-items {
  background: #fff;
  border: none;
  margin-top: 2px;
  box-shadow: 0 0 10px -3px rgba(0, 0, 0, 0.5);
}

#add-manager .selectric-items li {
  font-size: 22px;
  background-color: transparent;
  padding-left: 5px;
  color: #373d41;
}

#add-manager .selectric-items li:hover {
  background-color: #9eb670;
  color: #fff;
}

#add-manage .selectric-open .selectric-items {
  transition: .3s ease-out;
}

#scenario-form .touch-content .cke_bottom {
  display: none;
}

.menuSlideLink .comment-count-block {
  left: 40px;
  top: 3px;
}

.table__col.table__col_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.table__col.table__col_content .full {
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  transition: all, 0.5s;
}

.table__col.table__col_content .full.top {
  justify-content: flex-start;
}

.table__col.table__col_content .full.open {
  height: calc(100% - 24px);
}

.table__col.table__col_content i {
  margin: auto;
}

.table__col.table__col_name a.text-danger {
  color: #a94442;
}

.variables-list {
  margin: 15px 0;
  padding: 10px;
  background-color: #f1f3f8;
}

.variables-list-link-wrapper {
  font-size: 20px;
}

.variables-list-link-wrapper i {
  color: #9eb670;
  vertical-align: bottom;
}

.no-padding-left {
  padding-left: 0;
}

.no-padding-right {
  padding-right: 0;
}

.touch-title-wrapper .material-icons {
  position: relative;
}

.touch-title-wrapper .material-icons .icon-text {
  position: absolute;
  top: 6px;
}

.icon-wrapper {
  display: flex;
  align-items: center;
}

.icon-wrapper .icon-text {
  padding-top: 6px;
}

.touch-title-wrapper .material-icons .icon-text,
.icon-wrapper .icon-text {
  color: #686868;
  font-family: Neuron, sans-serif;
  font-size: 15px;
}

.touch-title-wrapper .material-icons .icon-text:hover,
.icon-wrapper .icon-text:hover {
  color: #9eb670;
}

.touch-title-wrapper .material-icons.cancel-cycle .icon-text,
.icon-wrapper.cancel-cycle .icon-text {
  left: 0;
}

.notification-deadline, .notification-services {
  padding-right: 20px;
}

.notification-deadline {
  padding-left: 15px;
}

#modal-call-back-form .modal__header .modal__title {
  font-size: 30px;
}

#modal-call-back-buy-form .modal__header .modal__title {
  font-size: 26px;
}

#modal-call-back-buy-form #comment {
  height: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.label_checkboxCheck {
  margin-left: 0;
  margin-right: 5px;
  margin-bottom: -2px;
}

.inactive-link {
  pointer-events: none;
  cursor: default;
}

.tariff-list-row {
  padding: 25px 0;
}

@media (min-width: 1200px) {
  .tariff-list-row {
    display: flex;
    justify-content: center;
  }
}

.tariff-list-header {
  padding: 15px 0;
  position: relative;
}

@media (max-width: 1200px) {
  .tariff-list-header {
    margin-top: 30px;
  }
}

.tariff-list-header h4, .tariff-list-header h3 {
  color: #ffffff;
}

.tariff-list-header h3 span {
  font-size: 20px;
}

.tariff-list-header h4 {
  font-size: 25px;
  text-transform: uppercase;
}

.tariff-list-header .tariff-tag {
  position: absolute;
  width: 100%;
  line-height: 1;
  top: -8px;
}

.tariff-list-header .tariff-tag span {
  background-color: #e8ba00;
  padding: 4px 15px;
  border-radius: 3px;
  color: #1d433c;
}

.tariff-list-description {
  overflow-wrap: break-word;
  padding: 2px;
  background-color: #f1f3f8;
}

@media (max-width: 1200px) {
  .tariff-list-description {
    display: none;
  }
}

.tariff-list-description ul li {
  padding: 5px 10px 5px 30px;
  position: relative;
  color: #1d433c;
}

.tariff-list-description ul li:before {
  display: block;
  content: url(/img/svg/check.svg);
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 8px;
}

.tariff-list-button {
  color: #fff;
  background-color: #9eb670;
  border-color: #9eb670;
  padding: 15px 30px;
}

.tariff-list-button.inactive-link {
  background-color: #64855b;
  border-color: #64855b;
}

.tariff-list-button:hover {
  background-color: #64855b;
  border-color: #64855b;
}

@media (max-width: 1395px) {
  .tariff-list-button {
    padding: 15px;
  }
}

.tariff-list-button-wrap {
  width: 100%;
  text-align: center;
  padding: 45px 0;
  background-color: #f1f3f8;
}

#profile-edit-form .account-title, .tab-container .account-title {
  font-size: 26px;
}

#modal-call-back-buy-form {
  overflow-x: hidden;
  overflow-y: auto;
}

#modal-call-back-buy-form .modal-dialog.modal__inner {
  margin-top: 50px;
}

#modal-change-payment .modal-dialog.modal__inner {
  margin-top: 50px;
}

.material-icons {
  color: #9eb670;
}

.material-icons.green200 {
  color: #9eb670;
}

.material-icons.red {
  color: red;
}

.tariff-list-description-content {
  padding: 15px;
}

.link-to-docs {
  flex-direction: column;
}

#current-buy-sum {
  width: 100%;
  border: 1px solid #ccc;
  margin-bottom: 15px;
}

.table-hover:hover .table__col,
.table__content .table__row.table-hover:nth-child(odd):hover .table__col {
  background-color: #f5f5f5;
  cursor: pointer;
}

.table-hover-demo:hover .table__col,
.table__content .table__row.table-hover-demo:nth-child(odd):hover .table__col {
  background-color: #f5f5f5;
  cursor: pointer;
}

#add-exchange .form-group .error-block, #add-exchange .input-wrap .help-block-error {
  top: unset;
}

.exchange-title {
  font-size: 20px;
}

.container_null {
  padding-right: 220px;
}

@media (max-width: 1200px) {
  .container_null {
    padding-right: 0px;
  }
}

.table__content .table__row .table__col.color_stop_1 {
  background-color: #f9e0e4;
}

#modal-to-archive .modal__content {
  padding: 0 80px 30px;
}

#exchange-comment-area {
  width: 100%;
  border: 1px solid #ccc;
  display: none;
  margin-top: 20px;
}

#modal-to-archive .button_center {
  margin-top: 20px;
}
